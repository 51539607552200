import { FC } from "react";
import { StyledAppHeader } from "./styles";
import logo from "../../assets/motorizado-logo-small.webp";
import { AppHamburgerMenu } from "../AppHamburgerMenu";
import { IoHomeOutline } from "react-icons/io5";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { SiTheconversation } from "react-icons/si";
import { FaCarOn } from "react-icons/fa6";
import { MdOutlineRequestPage } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { AppLink } from "../AppLink";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const AppHeader: FC = () => {
  const menu = [
    { link: "#home", name: "Home", icon: <IoHomeOutline /> },
    {
      link: "#servicos",
      name: "Nossos Serviços",
      icon: <HiOutlineWrenchScrewdriver />,
    },
    {
      link: "#clientes",
      name: "Qualidade",
      icon: <SiTheconversation />,
    },
    { link: "#comprar", name: "Comprar", icon: <FaCarOn /> },
    {
      link: "#contratar",
      name: "Contratar",
      icon: <MdOutlineRequestPage />,
    },
    { link: "#time", name: "Nosso time", icon: <RiTeamLine /> },
  ];

  const { width } = useWindowDimensions();

  return (
    <StyledAppHeader>
      <div className="left">
        <img src={logo} alt="Motorizado Logo" />
        <h1> Motorizado Consultoria</h1>
      </div>
      <div className="right">
        {width < 900 ? (
          <AppHamburgerMenu menuOptions={menu} />
        ) : (
          menu.map((item, index) => (
            <AppLink link={item.link}>
              {item.icon}
              {item.name}
            </AppLink>
          ))
        )}
      </div>
    </StyledAppHeader>
  );
};
