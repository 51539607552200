import { ChangeEventHandler, FC } from "react";
import { Styles } from "./styles";
import { Control, useController } from "react-hook-form";
import { IoIosRemoveCircleOutline } from "react-icons/io";

type Props = {
  name: string;
  label?: string;
  required?: boolean;
  control: Control<any>;
  disabled?: boolean;
  placeholder?: string;
  leadingLabelIcon?: React.ReactNode;
};

export const AppTextArea: FC<Props> = ({
  name,
  label,
  required = false,
  disabled = false,
  placeholder,
  control,
  leadingLabelIcon,
}) => {
  const {
    field: { onChange, value = "" },
    fieldState: { error },
  } = useController({ name, control });

  const change: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    onChange(event.target.value);
  };

  return (
    <Styles>
      <label htmlFor={name}>
        {leadingLabelIcon}
        {label}
        {required ? "*" : ""}
      </label>
      <textarea
        disabled={disabled}
        value={value}
        name={name}
        onChange={change}
        placeholder={placeholder}
      />
      {error && (
        <span className="input-error">
          <IoIosRemoveCircleOutline />
          {error?.message?.replace(name, label!)}
        </span>
      )}
    </Styles>
  );
};
