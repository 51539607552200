import { FC } from "react";
import { StyledEvaluatedCarsSection } from "./styles";
import { CarCard } from "../carCard";
import { FaCarOn } from "react-icons/fa6";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const EvaluatedCarsSection: FC<Props> = () => {
  return (
    <StyledEvaluatedCarsSection>
      <div id="comprar" className="section-title">
        <FaCarOn />
        <h1>Carros disponíveis</h1>
      </div>
      <p>
        Carros já avaliados com o nosso selo de qualidade, para você comprar
        despreocupado!
      </p>
      <div className="car-cards">
        <CarCard
          name="Kwid 1.0 2022 - Extra"
          description="35.000 KM Rodados Unico Dono"
          price="R$ 49.900"
        />
        <CarCard
          name="Fox 1.6 2019 - Connect"
          description="56.000 KM rodados, automatizado, todas revisões na concessionária"
          price="Abaixo da fipe!"
        />
        <CarCard
          name="Ford Ka 1.6 2016 - SEL"
          description="56.000 KM rodados, automatizado, todas revisões na concessionária"
          price="R$ 45.500"
        />
      </div>
    </StyledEvaluatedCarsSection>
  );
};
