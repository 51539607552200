import "reflect-metadata";
import { Container } from "inversify";
import { IWppService } from "../services/WppService/IWppService";
import { WppService } from "../services/WppService/WppService";
import { TYPES } from "./types";

const dipContainer = new Container();
dipContainer.bind<IWppService>(TYPES.WppService).to(WppService);

export { dipContainer };
