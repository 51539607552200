import styled from "styled-components";

export const StyledLandingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: block;
    padding: 0.5rem;
  }

  .landing {
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    gap: 5rem;
    padding-top: 2rem;

    .landing-left {
      h1 {
        font-size: 3.75rem;
        line-height: 1;
        letter-spacing: -0.05em;
        font-weight: 700;
      }

      p {
        font-size: 1.25rem;
        line-height: 1.75;
        color: #6c6c6c;
        max-width: 700px;
      }

      button {
        margin-top: 2rem;
      }
    }

    .landing-right {
      img {
        aspect-ratio: 1/1;
        width: 578px;
        height: auto;
        border-radius: 14px;

        @media screen and (max-width: 600px) {
          aspect-ratio: 16/9;

          max-width: 100%;
          height: auto;
        }
      }
    }
  }
`;
