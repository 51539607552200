import styled from "styled-components";

export const StyledCarCard = styled.div`
  width: 300px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 300px;
    height: 200px;
    margin: 0;
    padding: 0 !important;
    border-radius: 10px;
    padding: 1rem;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    height: auto;
  }

  .car-info {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .car-action {
    margin-top: 1rem;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
