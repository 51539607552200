import styled from "styled-components";

export const StyledExpertCard = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  width: fit-content;
  border-radius: 1rem;

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
