import styled from "styled-components";

export const StyledClientCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 232px;

  background-color: #fff;
  border-radius: 14px;
  padding: 24px;
  text-align: center;

  .client-card__client {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    img {
      object-fit: cover;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }

    .client-card__client_info {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.25rem;
      }

      h3 {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: #6c6c6c;
      }
    }
  }
`;
