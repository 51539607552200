import styled from "styled-components";

export const StyledAppButton = styled.button`
  background-color: #111827;
  color: #e5f3f3;
  font-family: "Libre Franklin", sans-serif;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    background-color: #1f2937;
    cursor: pointer;
  }
`;
