import { FC } from "react";
import { StyledCarCard } from "./styles";
import carimg from "../../assets/view-3d-car-small.webp";
import { AppButton } from "../../../../components/AppButton";
import { dipContainer } from "../../../../ioc/inverisfy.config";
import { IWppService } from "../../../../services/WppService/IWppService";
import { WppService } from "../../../../services/WppService/WppService";

type Props = {
  name: string;
  description: string;
  price: string;
};

export const CarCard: FC<Props> = ({ name, description, price }) => {
  const wppService = dipContainer.get<IWppService>(WppService);

  const onClickKnowMore = () => {
    const message = `Olá, vi o ${name} no site e me interessei. Poderia me passar mais informações? `;
    wppService.sendWppMessage(message);
  };

  return (
    <StyledCarCard>
      <img src={carimg} alt="" />
      <div className="car-info">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
      <div className="car-action">
        <h3>{price}</h3>
        <AppButton onClick={onClickKnowMore}>Saber mais</AppButton>
      </div>
    </StyledCarCard>
  );
};
