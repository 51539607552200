import styled from "styled-components";

export const StyledAppHamburgerMenu = styled.div<{ menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    font-size: 2rem;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 1px solid #ccc; // light grey hexcode
    }
  }

  .menu-modal {
    background-color: #fff;
    border: 1px solid #ccc; // light grey hexcode
    border-radius: 4px;
    display: ${(p) => (p.menuOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    margin-top: 2rem;
    position: absolute;
    padding: 1rem;
    gap: 0.5rem;
  }
`;
