import { FC } from "react";
import { StyledExpertsSection } from "./styles";
import { RiTeamLine } from "react-icons/ri";
import { ExpertCard } from "../expertCard";
import ricaomImg from "../../assets/ricaom-small.webp";
import alceuImg from "../../assets/alceu-small.webp";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const ExpertsSection: FC<Props> = () => {
  return (
    <StyledExpertsSection>
      <div id="time" className="section-title">
        <RiTeamLine />
        <h1>Nosso Time</h1>
      </div>
      <p>
        Conheça nossos especialistas com anos de experiência na indústria,
        garantindo avaliações de automóveis precisas e confiáveis.
      </p>
      <div className="experts-cards">
        <ExpertCard
          image={ricaomImg}
          name="Ricaom Barbosa"
          role="Consultor especialista"
        />
        <ExpertCard
          image={alceuImg}
          name="Alceu"
          role="Consultor especialista"
        />
      </div>
    </StyledExpertsSection>
  );
};
