import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Libre Franklin", sans-serif;
  margin-bottom: 1rem;

  label {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    margin-bottom: 0.5rem;
  }

  textarea {
    font-family: "Libre Franklin", sans-serif;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  textarea:focus {
    outline: 1px solid #a1a1aa;
    outline-offset: 1px;
  }
  .input-error {
    font-size: 14px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #d0312d;

    svg {
      width: 15px;
      fill: #d0312d;
    }
  }
`;
