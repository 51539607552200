import styled from "styled-components";

export const StyledClientsSection = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  background-color: #f3f4f6;

  .section-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
      }
    }

    h1 {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: -0.05em;
      font-weight: 700;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  @media screen and (max-width: 600px) {
    p:nth-last-child(2) {
      text-align: center;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.625;
    margin-left: auto;
    margin-right: auto;
  }

  .client-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
`;
