import { FC } from "react";
import { FeatureCard } from "../featureCard";
import { StyledFeatureSection } from "./styles";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { TiClipboard } from "react-icons/ti";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { SiMoneygram } from "react-icons/si";
import { MdOutlinePriceCheck } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { TbProgressCheck } from "react-icons/tb";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const FeatureSection: FC<Props> = () => {
  return (
    <StyledFeatureSection>
      <div id="servicos" className="section-title">
        <HiOutlineWrenchScrewdriver />
        <h1>Nossos serviços</h1>
      </div>
      <p>
        Nossas avaliações fornecem informações reais e certeiras para ajuda-lo a
        tomar as melhores decisões!
      </p>

      <div className="feature-cards">
        <FeatureCard
          icon={<TiClipboard />}
          title="Avaliações personalizadas"
          description="Nossas avaliações são feitas sob medida para atender as suas necessidades."
        />
        <FeatureCard
          icon={<HiOutlineClipboardDocumentCheck />}
          title="Documentação"
          description="Agilizamos de forma simples burocracias como a transferencia do seu carro."
        />
        <FeatureCard
          icon={<SiMoneygram />}
          title="Vendemos seu carro"
          description="Venda flash em até 24 horas ou venda para o cliente final em nossas plataformas."
        />
        <FeatureCard
          icon={<MdOutlinePriceCheck />}
          title="Preço competitivo"
          description="Nossos preços são competitivos e justos, garantindo a melhor avaliação do mercado."
        />
        <FeatureCard
          icon={<GrUserExpert />}
          title="Experiência"
          description="Nossa equipe de especialistas possui anos de experiência na indústria automotiva, garantindo avaliações precisas e confiáveis."
        />
        <FeatureCard
          icon={<TbProgressCheck />}
          title="Processo conveniente"
          description="Tornamos o processo de avaliação do carro fácil e descomplicado, com agendamento flexível e uma abordagem simplificada."
        />
      </div>
    </StyledFeatureSection>
  );
};
