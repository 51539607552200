import { ChangeEventHandler, FC } from "react";
import { Control, useController } from "react-hook-form";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { Styles } from "./styles";

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  type?: "text" | "email" | "password";
  control: Control<any>;
  disabled?: boolean;
  required?: boolean;
  leadingLabelIcon?: React.ReactNode;
};

export const AppInput: FC<Props> = ({
  label,
  name,
  placeholder,
  type = "text",
  control,
  disabled = false,
  required = false,
  leadingLabelIcon,
}) => {
  const {
    field: { onChange, value = "" },
    fieldState: { error },
  } = useController({ name, control });

  const change: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };

  return (
    <Styles>
      <label htmlFor={name}>
        {leadingLabelIcon}
        {label}
        {required ? "*" : ""}
      </label>
      <input
        disabled={disabled}
        value={value}
        onChange={change}
        type={type}
        name={name}
        placeholder={placeholder}
      />
      {error && (
        <span className="input-error">
          <IoIosRemoveCircleOutline />
          {error?.message?.replace(name, label!)}
        </span>
      )}
    </Styles>
  );
};
