import React, { FC } from "react";
import { StyledFeatureCard } from "./styles";

type Props = {
  icon: React.ReactNode;
  title: string;
  description: string;
};

export const FeatureCard: FC<Props> = ({ icon, title, description }) => {
  return (
    <StyledFeatureCard>
      <div className="feature-card__title">
        {icon}
        <h2>{title}</h2>
      </div>
      <p>{description}</p>
    </StyledFeatureCard>
  );
};
