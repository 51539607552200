import styled from "styled-components";

export const StyledFeatureCard = styled.div`
  .feature-card__title {
    display: flex;
    align-items: center;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }

    h2 {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 700;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0;
  }
`;
