import styled from "styled-components";

export const StyledFeatureSection = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .section-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    h1 {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: -0.05em;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 600px) {
    p:nth-last-child(2) {
      text-align: center;
    }
  }

  .feature-cards {
    width: 65%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      width: 90%;
    }
  }
`;
