import styled from "styled-components";

export const StyledEvaluatedCarsSection = styled.div`
  padding-block: 5rem;
  padding-block: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .section-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;

      h1 {
        text-align: center;
      }
    }

    h1 {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: -0.05em;
      font-weight: 700;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  @media screen and (max-width: 600px) {
    p:nth-last-child(2) {
      text-align: center;
    }
  }

  .car-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }
`;
