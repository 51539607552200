import { FC } from "react";
import { StyledAppLink } from "./styles";

type Props = {
  children: React.ReactNode;
  link: string;
  onClick?: () => void;
};

export const AppLink: FC<Props> = ({ children, link, onClick }) => {
  const onAppLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    console.log(document.querySelector(link)?.getBoundingClientRect().top);
    window.scrollTo({
      top: document.querySelector(link)?.getBoundingClientRect().top,
      behavior: "smooth",
    });

    onClick?.();
  };

  return (
    <StyledAppLink href={link} onClick={(event) => onAppLinkClick(event)}>
      {children}
    </StyledAppLink>
  );
};
