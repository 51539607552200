import { injectable } from "inversify";
import { IWppService } from "./IWppService";

@injectable()
export class WppService implements IWppService {
  private readonly wppLink: string = `https://wa.me/${process.env.REACT_APP_WPP_NUMBER}?text`;

  sendWppMessage(message: string): Promise<void> {
    try {
      window.open(`${this.wppLink}=${message}`, "_blank");
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
