import styled from "styled-components";

export const StyledAppFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  color: #fff;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      padding: 10px;
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: -0.05em;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 2rem;

    a {
      color: #fff;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;
