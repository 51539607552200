import styled from "styled-components";

export const StyledExpertsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  padding-top: 5rem;
  padding-bottom: 5rem;

  .section-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    h1 {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: -0.05em;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: 600px) {
    p:nth-last-child(2) {
      text-align: center;
    }
  }

  .experts-cards {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;
