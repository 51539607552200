import { FC } from "react";
import { StyledExpertCard } from "./styles";

import { IoBriefcaseOutline } from "react-icons/io5";

type Props = {
  name: string;
  role: string;
  image: string;
};

export const ExpertCard: FC<Props> = ({ name, role, image }) => {
  return (
    <StyledExpertCard>
      <img src={image} alt={name} />
      <div>
        <h3>{name}</h3>
        <p>
          <IoBriefcaseOutline /> {role}
        </p>
      </div>
    </StyledExpertCard>
  );
};
