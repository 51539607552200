import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório"),
  email: yup.string().email().required("Email é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
  carModel: yup.string().notRequired().nullable(),
});

export type FormData = yup.InferType<typeof schema>;
