import { FC } from "react";
import { StyledAppFooter } from "./styles";
import logo from "../../assets/motorizado-logo-small.webp";
import { FaInstagram } from "react-icons/fa";
import { FiLinkedin } from "react-icons/fi";

export const AppFooter: FC = () => {
  const onInstagramClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.open("https://www.instagram.com/motorizado.consultoria", "_blank");
  };

  return (
    <StyledAppFooter>
      <div className="left">
        <img src={logo} alt="Motorizado Consultoria" />© Motorizado Consultoria
        2024
      </div>
      <div className="right">
        <a
          onClick={(event) => onInstagramClick(event)}
          rel="external"
          href="https://www.instagram.com/motorizado.consultoria/"
        >
          <FaInstagram />
        </a>
        <FiLinkedin />
      </div>
    </StyledAppFooter>
  );
};
