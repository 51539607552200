import styled from "styled-components";

export const StyledAppHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e7;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      padding: 10px;
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: -0.05em;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 2rem;

    a {
      text-decoration: none;
      color: #000;
    }
  }
`;
