import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoCarSportOutline, IoPersonOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail, MdOutlineRequestPage } from "react-icons/md";
import { SiWhatsapp } from "react-icons/si";
import { AppButton } from "../../../../components/AppButton";
import { AppInput } from "../../../../components/AppInput";
import { AppTextArea } from "../../../../components/AppTextArea";
import { FormData, schema } from "./schema";
import { StyledRequestEvaluationSection } from "./styles";
import { dipContainer } from "../../../../ioc/inverisfy.config";
import { IWppService } from "../../../../services/WppService/IWppService";
import { WppService } from "../../../../services/WppService/WppService";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const RequestEvaluationSection: FC<Props> = () => {
  const wppService = dipContainer.get<IWppService>(WppService);
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      ...schema.cast({
        name: "",
        email: "",
        phone: "",
        carModel: "",
      }),
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onFormSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const message = `Olá me chamo ${
      data.name
    }, tenho interesse em contratar a avaliação. 
      Caso queira me contactar pode me mandar mensagem no WhatsApp: ${
        data.phone
      } ou no Email: ${data.email}.
      ${data.carModel ? `Quero avaliar esse carro: ${data.carModel}` : ""}`;
    await wppService.sendWppMessage(message);
  };

  return (
    <StyledRequestEvaluationSection>
      <div id="contratar" className="section-title">
        <MdOutlineRequestPage />
        <h1>Contratar avaliação</h1>
      </div>
      <p>
        Preencha o formulario abaixo para entrar em contato com o nosso time de
        experts.
      </p>
      <form className="car-eval-form" onSubmit={handleSubmit(onFormSubmit)}>
        <AppInput
          leadingLabelIcon={<IoPersonOutline />}
          control={control}
          name="name"
          label="Nome"
          type="text"
          placeholder="Seu nome"
          required
        />
        <AppInput
          control={control}
          name="email"
          label="Email"
          placeholder="exemplo@email.com"
          type="email"
          leadingLabelIcon={<MdOutlineAlternateEmail />}
          required
        />
        <AppInput
          control={control}
          leadingLabelIcon={<SiWhatsapp />}
          name="phone"
          label="Whats App"
          placeholder="(99) 99999-9999"
          type="text"
          required
        />
        <AppTextArea
          leadingLabelIcon={<IoCarSportOutline />}
          control={control}
          name="carModel"
          label="Informações do veículo"
          placeholder="Descreva o veículo desejado ou a ser avaliado"
        />
        <span>
          <IoIosInformationCircleOutline />
          Ao solicitar a avaliação você concorda em compartilhar conosco seu
          nome, email e número de telefone associado ao Whats App.
        </span>
        <AppButton>Solicitar</AppButton>
      </form>
    </StyledRequestEvaluationSection>
  );
};
