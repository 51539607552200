import styled from "styled-components";

export const StyledRequestEvaluationSection = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f3f4f6;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .section-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    h1 {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: -0.05em;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: 600px) {
    p:nth-last-child(2) {
      text-align: center;
    }
  }

  form {
    width: 500px;

    textarea {
      height: 100px;
    }

    button {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
    }

    span {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
      }
      font-size: 0.8rem;
    }
  }
`;
