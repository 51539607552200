import React, { FC, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { AppLink } from "../AppLink";
import { StyledAppHamburgerMenu } from "./styles";

type MenuOption = {
  name: string;
  link: string;
  icon: React.ReactNode;
};

type Props = {
  menuOptions: MenuOption[];
};

export const AppHamburgerMenu: FC<Props> = ({ menuOptions }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <StyledAppHamburgerMenu menuOpen={menuOpen}>
      <button onClick={handleMenuClick}>
        <IoMenu />
      </button>
      <div className="menu-modal">
        {menuOptions.map((menuOption, index) => (
          <AppLink onClick={handleLinkClick} link={menuOption.link}>
            {menuOption.icon}
            {menuOption.name}
          </AppLink>
        ))}
      </div>
    </StyledAppHamburgerMenu>
  );
};
