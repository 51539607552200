import { FC } from "react";
import { LandingSection } from "./components/landingSection";
import { StyledHomePage } from "./styles";
import { FeatureSection } from "./components/featureSection";
import { ClientsSection } from "./components/clientsSecton";
import { EvaluatedCarsSection } from "./components/evaluatedCarsSection";
import { RequestEvaluationSection } from "./components/requestEvaluationSection";
import { AppHeader } from "../../components/AppHeader";
import { ExpertsSection } from "./components/expertsSection";
import { AppFooter } from "../../components/AppFooter";

export const HomePage: FC = () => {
  return (
    <StyledHomePage>
      <AppHeader />
      <LandingSection />
      <FeatureSection />
      <ClientsSection />
      <EvaluatedCarsSection />
      <RequestEvaluationSection />
      <ExpertsSection />
      <AppFooter />
    </StyledHomePage>
  );
};
