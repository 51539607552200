import React, { FC } from "react";
import { StyledAppButton } from "./styles";

type Props = {
  children: React.ReactNode;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  onClick?: () => void;
};

export const AppButton: FC<Props> = ({
  children,
  leadingIcon,
  trailingIcon,
  onClick,
}) => {
  return (
    <StyledAppButton
      onClick={() => {
        return onClick?.();
      }}
    >
      {leadingIcon}
      {children}
      {trailingIcon}
    </StyledAppButton>
  );
};
