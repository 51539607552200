import { FC } from "react";
import { StyledLandingSection } from "./styles";
import CarDealer from "../../assets/car-dealer-small.webp";
import { LuCar } from "react-icons/lu";
import { AppButton } from "../../../../components/AppButton";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const LandingSection: FC<Props> = () => {
  const onButtonClick = () => {
    window.scrollTo({
      top: document.querySelector("#contratar")?.getBoundingClientRect().top,
      behavior: "smooth",
    });
  };

  return (
    <StyledLandingSection>
      <div id="home" className="landing">
        <div className="landing-left">
          <h1>Receba uma avaliação justa do seu carro</h1>
          <p>
            Nosso time de especialistas fornece avaliações completas e honestas
            para que você posso tomar a melhor decisão possível!
          </p>
          <AppButton onClick={onButtonClick} leadingIcon={<LuCar />}>
            Agendar avaliação
          </AppButton>
        </div>
        <div className="landing-right">
          <img src={CarDealer} srcSet={CarDealer} alt="Carro" />
        </div>
      </div>
    </StyledLandingSection>
  );
};
