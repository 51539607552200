import { FC } from "react";
import { StyledClientCard } from "./styles";
import userImage from "../../assets/66-small.webp";

type Props = {
  testimonial: string;
  name: string;
  //   image: string;
};

export const ClientCard: FC<Props> = ({ name, testimonial }) => {
  return (
    <StyledClientCard>
      <p>"{testimonial}"</p>
      <div className="client-card__client">
        <img src={userImage} alt="user" />
        <div className="client-card__client_info">
          <h2>{name}</h2>
          <h3>Cliente satisfeito</h3>
        </div>
      </div>
    </StyledClientCard>
  );
};
