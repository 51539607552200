import { FC } from "react";
import { StyledClientsSection } from "./styles";
import { ClientCard } from "../clientCard";
import { SiTheconversation } from "react-icons/si";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const ClientsSection: FC<Props> = () => {
  return (
    <StyledClientsSection>
      <div id="clientes" className="section-title">
        <SiTheconversation />
        <h1>O que nossos clientes dizem</h1>
      </div>
      <p>
        Ouça nossos clientes satisfeitos sobre sua experiência com nossos
        serviços de avaliação de automóveis.
      </p>
      <div className="client-cards">
        <ClientCard
          name="Joana"
          testimonial="O processo de avaliação do carro foi rápido e eficiente. Recebi um relatório detalhado que me ajudou a tomar uma decisão informada sobre meu veículo."
        />
        <ClientCard
          name="Joana"
          testimonial="O processo de avaliação do carro foi rápido e eficiente. Recebi um relatório detalhado que me ajudou a tomar uma decisão informada sobre meu veículo."
        />
        <ClientCard
          name="Joana"
          testimonial="O processo de avaliação do carro foi rápido e eficiente. Recebi um relatório detalhado que me ajudou a tomar uma decisão informada sobre meu veículo."
        />
      </div>
    </StyledClientsSection>
  );
};
